<route>
{
  "meta": {
    "auth": "productCategory"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">分类名称</span>
          <el-input v-model.trim="searchValue" class="searchInput" placeholder="分类名称" clearable> </el-input>
        </el-col>
        <el-col :span="12">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>分类列表</span>
        <el-button v-auth="'productCategoryAdd'" type="success" @click="handleAddDialog" style="float: right; "
          >添加分类</el-button
        >
      </div>
      <el-table
        :data="firstLevel"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        default-expand-all
        header-cell-class-name="tableHeader"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <!-- :cell-class-name="tableCellClassName" -->
        <el-table-column label="一级" align="center">
          <template slot-scope="scope">
            <div @click="firstClick(scope)">
              {{ scope.row.one }}
              <i v-if="!scope.row.isOpened && scope.row.one" class="el-icon-arrow-down"></i>
              <i v-if="scope.row.isOpened && scope.row.one" class="el-icon-arrow-up"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="二级" align="center">
          <template slot-scope="scope">
            <div @click="secondClick(scope)">
              <span v-if="!isNaN(scope.row.second)">(</span>
              {{ scope.row.second }}
              <span v-if="!isNaN(scope.row.second)">)</span>
              <i
                v-if="!scope.row.isOpened && scope.row.second && isNaN(scope.row.second)"
                class="el-icon-arrow-down"
              ></i>
              <i v-if="scope.row.isOpened && scope.row.second && isNaN(scope.row.second)" class="el-icon-arrow-up"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="三级" align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="!isNaN(scope.row.third)">(</span>
              {{ scope.row.third }}
              <span v-if="!isNaN(scope.row.third)">)</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="productCount" label="关联商品" align="center"> </el-table-column>
        <el-table-column label="是否显示" align="center">
          <template slot-scope="scope"> {{ scope.row.hideStatus ? '显示' : '不显示' }}</template>
        </el-table-column>
        <el-table-column prop="sortNo" label="排序" align="center"> </el-table-column>
        <el-table-column label="分类上架" align="center">
          <template slot-scope="{ row }">
            {{ row.requestSources | calcText }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-auth="'productCategoryEdit'" type="text" size="small" @click="edit(scope)">编辑</el-button>
            <el-button
              v-auth="'productCategoryDelete'"
              type="text"
              @click="showDelete(scope)"
              size="small"
              class="deleteBtnText"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 删除分类的组件 -->
    <bm-deleteItem
      url="boom-center-product-service/sysAdmin/productCategory/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>

    <!-- 添加分类的弹框 -->

    <el-dialog :close-on-click-modal="false" title="添加分类" :visible.sync="addClassifyDialog" width="60%">
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="el-transfer-panel">
            <p class="el-transfer-panel__header">
              <span class="el-checkbox__input "> <span class="el-checkbox__label"> 一级分类 </span></span>
            </p>
            <div class="classifyBody">
              <div class="classifyItems">
                <div
                  v-for="(item, index) in firstLevelDialog"
                  :key="index"
                  class="classifyItem"
                  :class="{ activeClass: itemFirstId === item.id }"
                  @click="selectFirst(item.id)"
                >
                  {{ item.categoryName }}
                </div>
              </div>

              <div>
                <el-input v-if="addFirst === true" class="className" v-model.trim="addFirstName"></el-input>
                <el-button v-if="addFirst === true" type="text" @click="addClassName">确定</el-button>

                <el-button v-else type="text" class="addClass" @click="addFirst = true">增加一级分类</el-button>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8"
          ><div>
            <div class="el-transfer-panel">
              <p class="el-transfer-panel__header">
                <span class="el-checkbox__input "> <span class="el-checkbox__label"> 二级分类 </span></span>
              </p>
              <div class="classifyBody">
                <div class="classifyItems">
                  <div
                    v-for="(item, index) in secondClass"
                    :key="index"
                    class="classifyItem"
                    :class="{ activeClass: itemSecondId === item.id }"
                    @click="selectSecond(item.id)"
                  >
                    {{ item.categoryName }}
                  </div>
                </div>

                <div>
                  <el-input v-if="addSecond === true" class="className" v-model.trim="secondName"></el-input>
                  <el-button v-if="addSecond === true" type="text" @click="addSecondClassName">确定</el-button>

                  <el-button v-else type="text" class="addClass" @click="addSecond = true">增加二级分类</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <div class="el-transfer-panel">
              <p class="el-transfer-panel__header">
                <span class="el-checkbox__input "> <span class="el-checkbox__label"> 三级分类 </span></span>
              </p>
              <div class="classifyBody">
                <div class="classifyItems">
                  <div
                    v-for="(item, index) in thirdClass"
                    :key="index"
                    class="classifyItem"
                    :class="{ activeClass: itemId === item.id }"
                  >
                    {{ item.categoryName }}
                  </div>
                </div>

                <div>
                  <el-input v-if="addThird === true" class="className" v-model.trim="thirdName"></el-input>
                  <el-button v-if="addThird === true" type="text" @click="addThirdClassName">确定</el-button>

                  <el-button v-else type="text" class="addClass" @click="addThird = true">增加三级分类</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button round @click="addClassifyDialog = false">关闭添加分类</el-button>
      </span>
    </el-dialog>
    <!-- 编辑一级分类 -->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑一级分类"
      :visible.sync="firstDialog"
      width="25%"
      class="classifyDialog"
    >
      <el-form ref="firstForm" :rules="firstRules" :model="firstForm" label-width="80px">
        <el-form-item label="分类上架" prop="requestSources">
          <el-checkbox-group v-model="firstForm.requestSources">
            <el-checkbox :label="1">微信小程序</el-checkbox>
            <el-checkbox :label="2">H5</el-checkbox>
            <!-- <el-checkbox :label="3">后台</el-checkbox> -->
            <!-- <el-checkbox :label="4">抖音小程序</el-checkbox> -->
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="分类名称" prop="categoryName">
          <el-input v-model.trim="firstForm.categoryName"></el-input>
        </el-form-item>
        <el-form-item label="分类排序" prop="sortNo">
          <el-input-number
            v-model="firstForm.sortNo"
            controls-position="right"
            :min="0"
            :step="1"
            :max="9999"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="是否显示">
          <el-switch v-model="firstForm.hideStatus" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <!-- <el-form-item label="分类图标" prop="iconUrl">
          <bm-upload type="system" v-model="firstForm.iconUrl"></bm-upload>
        </el-form-item>
        <el-form-item label="分类背景图片" prop="headUrl">
          <bm-upload type="system" v-model="firstForm.headUrl"></bm-upload>
        </el-form-item> -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button round @click="firstDialog = false">取消</el-button>
        <el-button round type="primary" @click="editProductCategory(0)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑二级，三级分类 -->

    <el-dialog
      :close-on-click-modal="false"
      :title="secondTitle"
      :visible.sync="secondDialog"
      width="25%"
      class="classifyDialog"
    >
      <el-form :rules="firstRules" ref="secondOrThirdForm" :model="secondOrThirdForm" label-width="80px">
        <el-form-item label="分类上架" prop="requestSources">
          <el-checkbox-group v-model="secondOrThirdForm.requestSources">
            <el-checkbox :label="1" :disabled="isDisabledWxSource">微信小程序</el-checkbox>
            <el-checkbox :label="2" :disabled="isDisabledHSource">H5</el-checkbox>
            <!-- <el-checkbox :label="3">后台</el-checkbox> -->
            <!-- <el-checkbox :label="4">抖音小程序</el-checkbox> -->
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="所属分类">
          <el-select v-model="secondOrThirdForm.parentId" placeholder="请选择所属分类" disabled>
            <el-option
              v-for="(item, index) in firstLevel"
              :label="item.categoryName"
              :value="item.id"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称" prop="categoryName">
          <el-input v-model.trim="secondOrThirdForm.categoryName"></el-input>
        </el-form-item>
        <el-form-item label="分类排序" prop="sortNo">
          <el-input-number
            v-model="secondOrThirdForm.sortNo"
            controls-position="right"
            :min="0"
            :step="1"
            :max="9999"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="是否显示">
          <el-switch v-model="secondOrThirdForm.hideStatus" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="分类图标" prop="iconUrl">
          <bm-upload type="system" v-model="secondOrThirdForm.iconUrl"></bm-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="secondDialog = false">取 消</el-button>
        <el-button type="primary" round @click="editProductCategory(1)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteRowItem } from '@/mixin/deleteRowItem'
export default {
  mixins: [deleteRowItem],
  data() {
    return {
      //删除分类的
      timestamp: 0,

      // 添加分类的名称
      secondName: '',
      thirdName: '',
      addFirstName: '',
      // 添加分类弹框真假值决定是否显示输入框和确定按钮
      addFirst: false,
      addSecond: false,
      addThird: false,
      // 添加分类数据展示列表
      thirdClass: [],
      secondClass: [],
      firstLevel: [],
      // 弹框点击一级或二级分类的ID
      itemFirstId: '',
      itemSecondId: '',
      // 二级或者三级分类弹框的标题
      secondTitle: '',
      firstRules: [],
      secondRules: [],
      // 分类的图片或者图标
      firstLevelDialog: [],
      searchValue: '',
      // 是否显示弹框
      addClassifyDialog: false,
      firstDialog: false,
      secondDialog: false,
      defaultRequestSources: [1, 2, 3],
      // 编辑一级分类的表单
      firstForm: {
        requestSources: [],
        parentId: 0,
        sortNo: 0,
        categoryName: '',
        iconUrl: '',
        headUrl: '',
        hideStatus: 0
      },
      // 编辑二级或者三级分类的表单
      secondOrThirdForm: {
        requestSources: [],
        parentId: '',
        sortNo: 0,
        categoryName: '',
        iconUrl: '',
        hideStatus: 0
      },
      firstRules: {
        requestSources: [
          {
            required: true,
            message: '请选择商家上架',
            trigger: 'change',
            type: 'array',
            min: 2
          }
        ],
        categoryName: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur'
          }
        ],
        sortNo: [
          {
            required: true,
            message: '请输入分类排序',
            trigger: 'blur'
          }
        ]
      },
      // 是否在编辑一级或者二级分类
      isEditFirst: false,
      isEditSecond: false,
      searchValue: '',
      currentLevel: 0, //标识当前的级别
      secResouces: [],
      thirdResouces: [],
      saveExpandId: 0,
      editRowData: {}
    }
  },
  created() {
    var that = this
    document.onkeydown = function(e) {
      var key = window.event.keyCode
      if (key == 13) {
        that.getList()
      }
    }
    this.getRules()
    // 获取表格列表的一级分类 && 获取 添加分类的一级分类
    this.getList()
    this.productCategoryDialog()
  },
  filters: {
    calcText(val) {
      if (val.length === 3) {
        //pc,微信小程序,h5
        return '多端'
      } else {
        let filterVal = val.filter(item => item !== 3) //过滤后台，不进行展示
        return filterVal
          .map(item => {
            if (item === 1) {
              return '微信小程序'
            } else if (item === 2) {
              return 'H5'
            }
          })
          .join()
      }
    }
  },
  computed: {
    isDisabledWxSource() {
      return this.editRowData.parent && !this.editRowData.parent.requestSources.includes(1)
    },
    isDisabledHSource() {
      return this.editRowData.parent && !this.editRowData.parent.requestSources.includes(2)
    }
  },
  methods: {
    getRules() {
      // this.firstRules = this.$initRules([
      //   {
      //     label: '分类名称',
      //     value: 'categoryName',
      //     type: 'input',
      //     required: true
      //   },
      //   {
      //     label: '分类排序',
      //     value: 'sortNo',
      //     type: 'input',
      //     required: true
      //   },
      //   {
      //     label: '分类图标',
      //     value: 'iconUrl',
      //     type: 'upload',
      //     required: false
      //   },
      //   {
      //     label: '分类背景',
      //     value: 'headUrl',
      //     type: 'upload',
      //     required: false
      //   }
      // ])
      // this.secondRules = this.$initRules([
      //   {
      //     label: '分类名称',
      //     value: 'categoryName',
      //     type: 'input',
      //     required: true
      //   },
      //   {
      //     label: '分类排序',
      //     value: 'sortNo',
      //     type: 'input',
      //     required: true
      //   }
      //   // {
      //   //   label: '分类图标',
      //   //   value: 'iconUrl',
      //   //   type: 'upload',
      //   //   required: true
      //   // }
      // ])
    },
    resetSearch() {
      this.searchValue = ''
      this.getList()
    },
    // =============处理表格的方法============
    // 点击二级分类
    secondClick(scope) {
      if (!isNaN(scope.row.second)) {
        return
      }
      if (scope.row.second)
        if (!this.firstLevel[scope.$index].isOpened) {
          this.$api.product
            .product('thirdLevelList', {
              params: {
                parentId: scope.row.id
              }
            })
            .then(list => {
              list.forEach(item => {
                item.level = 3
                item.third = item.categoryName
                item.isOpened = false
              })
              this.firstLevel.splice(scope.$index + 1, 0, ...list)
              this.firstLevel[scope.$index].isOpened = true
            })
        } else {
          this.firstLevel.splice(scope.$index + 1, scope.row.third)
          this.firstLevel[scope.$index].isOpened = false
        }
    },

    // 点击一级分类
    firstClick(scope) {
      this.saveExpandId = scope.row.id
      if (!this.firstLevel[scope.$index].isOpened) {
        ///如果是false的情况，就请求
        this.$api.product
          .product('secondLevelList', {
            params: {
              parentId: scope.row.id
            }
          })
          .then(list => {
            if (scope.row.level === 1) {
              list.forEach(item => {
                item.level = 2
                item.second = item.categoryName
                item.third = item.childThirdLevelCount
                item.isOpened = false
              })
            }
            this.firstLevel.splice(scope.$index + 1, 0, ...list)

            this.firstLevel[scope.$index].isOpened = true
            console.log(this.firstLevel[scope.$index].isOpened)
          })
      }

      if (this.firstLevel[scope.$index].isOpened) {
        // 如果是关闭的话，我就删除
        let a = this.firstLevel.slice(scope.$index + 1)
        let b = []
        for (let i = 0; i < a.length; i++) {
          if (a[i].level === 1) {
            break
          } else {
            b.push(a[i])
            continue
          }
        }
        this.firstLevel.splice(scope.$index + 1, b.length)
        this.firstLevel[scope.$index].isOpened = false
        console.log(this.firstLevel[scope.$index].isOpened)
      }
    },
    // 获取表格的一级分类
    getList() {
      this.$api.product
        .product('firstLevelList', {
          params: {
            firstCategoryName: this.searchValue,
            size: 500
          }
        })
        .then(res => {
          let { list } = res
          list.forEach(item => {
            item.level = 1
            item.one = item.categoryName
            item.isOpened = false
            item.second = parseInt(item.childSecondLevelCount) //转成数字，在表格内有用
            item.third = item.childThirdLevelCount
          })
          this.firstLevel = list
        })
    },

    // =====================修改分类的方法=====================
    handleAddDialog() {
      this.addClassifyDialog = true
      this.productCategoryDialog()
    },
    getEditData(id) {
      return this.$api.product.product('getProductCategory', id).then(list => {
        this.editRowData = list
      })
    },
    // 点击编辑按钮，根据值显示不同的编辑弹框
    edit(formVal) {
      this.getEditData(formVal.row.id).then(_ => {
        if (formVal.row.level === 1) {
          this.isEditFirst = false
          this.firstDialog = true

          let curSet = new Set()
          this.editRowData.childList.forEach(item => {
            item.requestSources.forEach(i => {
              curSet.add(i)
            })
          })
          let result = Array.from(curSet)
          this.secResouces = result

          this.firstForm = JSON.parse(JSON.stringify(formVal.row))
        } else if (formVal.row.level === 2) {
          this.secondTitle = '编辑二级分类'

          let curSet = new Set()
          this.editRowData.childList.forEach(item => {
            item.requestSources.forEach(i => {
              curSet.add(i)
            })
          })
          let result = Array.from(curSet)
          this.thirdResouces = result

          this.secondDialog = true
          this.isEditSecond = false
          this.secondOrThirdForm = JSON.parse(JSON.stringify(formVal.row))
        } else {
          this.secondTitle = '编辑三级分类'

          this.secondDialog = true
          this.isEditSecond = false
          this.secondOrThirdForm = JSON.parse(JSON.stringify(formVal.row))
        }
        this.currentLevel = formVal.row.level
      })
    },

    // 修改分类
    editProductCategory(val) {
      // 根据传入的id，判断是修改几级分类
      if (val === 0) {
        this.$refs['firstForm'].validate((valid, validObj) => {
          if (!valid) {
            this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
            return
          } else {
            let bool = true
            if (this.secResouces.length > this.firstForm.requestSources.length) {
              // 子的上架平台大于一级平台
              bool = false
            } else {
              // 二级的上架平台 不存在与一级平台
              let hasNoExit = this.secResouces.some(item => !this.firstForm.requestSources.includes(item))
              bool = !hasNoExit
            }
            if (!bool) {
              this.$message.error('请先修改二级商品分类')
              return
            }
            this.$api.product
              .product('putProductCategory', {
                requestSources: this.firstForm.requestSources,
                categoryName: this.firstForm.categoryName,
                headUrl: this.firstForm.headUrl,
                iconUrl: this.firstForm.iconUrl,
                id: this.firstForm.id.toString(),
                parentId: this.firstForm.parentId,
                sortNo: parseInt(this.firstForm.sortNo),
                hideStatus: this.firstForm.hideStatus
              })
              .then(res => {
                this.$message.success('恭喜你，' + this.firstForm.categoryName + '修改成功')

                this.firstDialog = false
                this.getList()
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
          }
        })
      } else {
        this.$refs['secondOrThirdForm'].validate((valid, validObj) => {
          if (!valid) {
            this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
            return true
          } else {
            if (this.currentLevel === 2) {
              let bool = true
              if (this.thirdResouces.length > this.secondOrThirdForm.requestSources.length) {
                // 三级的上架平台大于二级平台
                bool = false
              } else {
                // 三级的上架平台 不存在与二级平台
                let hasNoExit = this.thirdResouces.some(item => !this.secondOrThirdForm.requestSources.includes(item))
                bool = !hasNoExit
              }
              if (!bool) {
                this.$message.error('请先修改三级商品分类')
                return
              }
            }
            this.$api.product
              .product('putProductCategory', {
                requestSources: this.secondOrThirdForm.requestSources,
                categoryName: this.secondOrThirdForm.categoryName,
                iconUrl: this.secondOrThirdForm.iconUrl,
                id: this.secondOrThirdForm.id,
                parentId: this.secondOrThirdForm.parentId,
                sortNo: this.secondOrThirdForm.sortNo,
                hideStatus: this.secondOrThirdForm.hideStatus
              })
              .then(res => {
                this.$message.success('恭喜你，' + this.secondOrThirdForm.categoryName + '修改成功')
                this.secondDialog = false
                this.getList()
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
          }
        })
      }
    },

    // =====================弹框方法=======================

    // 弹框添加一级分类
    addClassName() {
      if (!this.addFirstName) {
        this.$message.error('商品分类名不能为空')
        return
      }

      this.$api.product
        .product('postProductCategory', {
          requestSources: this.defaultRequestSources, //默认小程序，h5, pc
          categoryName: this.addFirstName,
          parentId: 0,
          rootId: 0
        })
        .then(res => {
          this.addFirst = false //  取消输入框和确定
          this.addFirstName = '' // 清楚输入框的值
          this.productCategoryDialog() //添加完成后需要请求
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '添加一级分类失败')
        })
    },

    // 弹框添加二级分类
    addSecondClassName() {
      let curItem = this.firstLevel.find(item => item.id === this.itemFirstId)
      console.log(curItem)
      if (!this.secondName) {
        this.$message.error('商品分类名不能为空')
        return
      }
      this.$api.product
        .product('postProductCategory', {
          requestSources: curItem.requestSources, //取决于一级分类
          categoryName: this.secondName,
          parentId: this.itemFirstId,
          rootId: this.itemFirstId
        })
        .then(res => {
          this.addSecond = false
          this.secondName = ''
          this.selectFirst(this.itemFirstId)
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '添加二级失败')
        })
    },
    // 弹框添加三级分类
    addThirdClassName() {
      let curItem = this.secondClass.find(item => item.id === this.itemSecondId)
      console.log(curItem)
      if (!this.thirdName) {
        this.$message.error('商品分类名不能为空')
        return
      }
      if (this.itemSecondId === '') {
        this.$message.error('二级分类不能为空')
        return
      }

      this.$api.product
        .product('postProductCategory', {
          requestSources: curItem.requestSources, //取决于二级分类
          categoryName: this.thirdName,
          parentId: this.itemSecondId,
          rootId: this.itemFirstId
        })
        .then(res => {
          this.addThird = false
          this.thirdName = ''
          this.selectSecond(this.itemSecondId)
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '添加三级失败')
        })
    },
    // 向后端发起请求获取弹框一级分类
    productCategoryDialog() {
      this.$api.product
        .product('firstLevelList')
        .then(res => {
          let { list } = res
          if (list.length) {
            this.firstLevelDialog = list
            this.selectFirst(list[0].id)
          } else {
            this.firstLevelDialog = []
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '获取弹框一级分类失败')
        })
    },
    // 向后端发起请求获取弹框二级分类
    selectFirst(id) {
      this.$api.product
        .product('secondLevelList', {
          params: {
            parentId: id
          }
        })
        .then(res => {
          this.itemSecondId = ''
          this.itemFirstId = id
          if (res.length) {
            this.secondClass = res
            this.thirdClass = []
          } else {
            this.secondClass = []
            this.thirdClass = []
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '获取弹框二级分类失败')
        })
    },
    // 向后端发起请求获取弹框三级分类
    selectSecond(id) {
      this.$api.product
        .product('thirdLevelList', {
          params: {
            parentId: id
          }
        })
        .then(res => {
          this.itemSecondId = id
          if (res.length) {
            this.thirdClass = res
          } else {
            this.thirdClass = []
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '获取弹框二级分类失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.classifyBody {
  text-align: center;
  height: 300px;
  .classifyItems {
    line-height: 30px;
    cursor: grab;
    height: 270px;
    overflow-y: scroll;
    width: calc(100% + 18px);
  }
  .className {
    width: 80%;
    margin: 0 5px;
  }
  .activeClass {
    color: @color-primary-select;
    background: #f5f5f5;
  }
  .addClass {
    position: absolute;
    bottom: 0;
    left: calc(50% - 40px);
    width: 80px;
  }
}

.el-transfer-panel .el-transfer-panel__header {
  margin: 0;
  text-align: center;
  padding-left: 0;
}

.classifyDialog {
  .el-select,
  .el-input-number,
  .el-input {
    width: 300px;
  }
}
</style>
